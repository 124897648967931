import React from "react"
import ReactPlayer from "react-player"
import Img from "gatsby-image"
import OrderBtn from "./orderBtn"
import FadeIn from "./fadeIn"
import FlyIn from "./flyIn"
import VisibilitySensor from "react-visibility-sensor"

import styles from "../../styles/components/funnel/featurePage.module.scss"

const FeaturePage = props => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.details}>
          <div>
            <h2 className={styles.titleSub}>{props.titleSub}</h2>
            <h1 className={styles.titleMain}>{props.titleMain}</h1>
          </div>
          <FlyIn left>
            <div className={styles.description}>{props.content}</div>
          </FlyIn>
        </div>
        <FadeIn delay={500}>
          <OrderBtn />
        </FadeIn>
      </div>
      {/* {props.img && (
        <div
          className={styles.img}
          style={{ backgroundImage: `url(${props.img})` }}
        ></div>
      )} */}

      {props.img && (
        <div className={styles.img}>
          <Img
            fluid={props.img}
            style={{ height: "100%", width: "100%" }}
            alt=""
          />
        </div>
      )}

      {props.video && (
        <div className={styles.video}>
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <ReactPlayer
                url={props.video}
                width="100%"
                height="100%"
                playing={isVisible ? true : false}
                controls={false}
                loop
                muted
              />
            )}
          </VisibilitySensor>
        </div>
      )}
    </div>
  )
}

export default FeaturePage
