import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Order from "./order"
import { FaTimes } from "react-icons/fa"
import Img from "gatsby-image"
import styles from "../../styles/components/funnel/orderModal.module.scss"

const OrderModal = props => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "funnel/background.webp" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className={styles.backdrop} onClick={props.onHide} id="order-modal"></div>
      <div className={styles.modalMain}>
        <div className={styles.bg}>
          <Img
            fluid={data.bg.childImageSharp.fluid}
            style={{ height: "100%", width: "100%" }}
            alt=""
          />
        </div>

        <Order closeHandler={props.onHide} />
        <div className={styles.btn} onClick={props.onHide}>
          <FaTimes />
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrderModal
