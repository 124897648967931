import React from "react"

import styles from '../../styles/components/funnel/formInput.module.scss'

const FormInput = props => {
  return (
    <div className={styles.inputDiv}>
      <label>{props.label}</label>
      <input
        name={props.name}
        type={props.type}
        required={props.required}
        value={props.value}
        onChange={props.onChange}
      ></input>
    </div>
  )
}

export default FormInput
