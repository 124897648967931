import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import OrderBtn from "./orderBtn"
import Footer from "./footer"
import Img from "gatsby-image/withIEPolyfill"

import styles from "../../styles/components/funnel/endPage.module.scss"

const EndPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "funnel/end-page.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg2: file(relativePath: { eq: "funnel/end-page.webp" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg3: file(relativePath: { eq: "funnel/end-page.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    {
      ...data.bg.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
    {
      ...data.bg2.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
    {
      ...data.bg3.childImageSharp.fluid,
      media: `(max-width: 1024px)`,
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.bg}>
        <Img
          fluid={sources}
          style={{ height: "100%", width: "100%" }}
          objectFit="cover"
          alt=""
        />
      </div>
      <div className={styles.titles}>
        <p className={`${styles.titleMain} display-2`}>
          Power Your Home &amp; Office With Solar!
        </p>
        <p className={styles.titleSub}>Click the button below to start</p>
      </div>
      <OrderBtn inverted />
      <Footer />
    </div>
  )
}

export default EndPage
