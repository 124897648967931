import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FadeIn from "./fadeIn"
import Img from "gatsby-image/withIEPolyfill"

import styles from "../../styles/components/funnel/specsPage.module.scss"

const SpecsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "funnel/SpecsPage.webp" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg2: file(relativePath: { eq: "funnel/SpecsPage.webp" }) {
        childImageSharp {
          fluid(maxWidth: 450, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    {
      ...data.bg.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...data.bg2.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.img}>
        <Img
          fluid={sources}
          style={{ height: "100%", width: "100%" }}
          objectFit="contain"
          alt=""
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <h1>
            <strong>Solar Panel</strong> Specs
          </h1>
        </div>
        <div className={styles.specList}>
          <div className={styles.spec}>
            <h4>Wattage</h4>
            <FadeIn>
              <p>325-340W</p>
            </FadeIn>
          </div>
          <div className={styles.spec}>
            <h4>Operating Temperature</h4>
            <FadeIn>
              <p>
                -40<sup>0</sup>C up to 80<sup>0</sup>C
              </p>
            </FadeIn>
          </div>
          <div className={styles.spec}>
            <h4>Dimensions</h4>
            <FadeIn>
              <p>77.01" x 39.06" x 1.57"</p>
            </FadeIn>
          </div>
          <div className={styles.spec}>
            <h4>Frame Type</h4>
            <FadeIn>
              <p>Anodized aluminium alloy</p>
            </FadeIn>
          </div>
          <div className={styles.spec}>
            <h4>Warranty</h4>
            <FadeIn>
              <p>12 year product warranty</p>
              <p>25 year linear performance warranty</p>
            </FadeIn>
          </div>
        </div>
        <div className={styles.specList}>
          <div className={styles.spec}>
            <h4>Certifications</h4>
            <FadeIn>
              <p>IEC 61215, IEC 61730</p>
              <p>ISO 9001:2015</p>
              <p>ISO 14001:2015</p>
              <p>OHSAS 18001:2007</p>
              <p>IEC TS 62941:2016</p>
            </FadeIn>
          </div>
          <div className={styles.spec}>
            <h4>Inverter Power</h4>
            <FadeIn>
              <p>5kW / 6kW / 10kW</p>
              <p>Over 98% Max. efficiency</p>
            </FadeIn>
          </div>
          <div className={styles.spec}>
            <h4>Inverter Dimensions</h4>
            <FadeIn>
              <p>310mm x 563mm x 219mm</p>
            </FadeIn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecsPage
