import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../navigation/header"
import OrderModal from "./orderModal"
import { Container } from "reactstrap"
import { Button } from "reactstrap"
import Img from "gatsby-image"

import styles from "../../styles/components/funnel/mainPage.module.scss"
import icon1 from "../../images/funnel/mainPage/icon1.png"
import icon2 from "../../images/funnel/mainPage/icon2.png"
import icon3 from "../../images/funnel/mainPage/icon3.png"

const MainPage = () => {
  const [modalShow, setModalShow] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "funnel/background.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg2: file(relativePath: { eq: "funnel/background.webp" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg3: file(relativePath: { eq: "funnel/background.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    {
      ...data.bg.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
    {
      ...data.bg2.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
    {
      ...data.bg3.childImageSharp.fluid,
      media: `(max-width: 1024px)`,
    },
  ]
  return (
    <div className={styles.body}>
      <div className={styles.bg}>
        <Img
          fluid={sources}
          style={{ height: "100%", width: "100%" }}
          alt=""
          loading="eager"
          backgroundColor="#ff8000"
        />
      </div>
      <Header />
      <Container>
        <div className={`${styles.content}`}>
          <h1 className={`${styles.title} display-2`}>
            Solar For Your Home &amp; Business
          </h1>
          <p className="text-center">
            In 2016, Solar became the cheapest source of electricity in the
            world.
          </p>
          <Button
            size="lg"
            className={`d-block ${styles.orderBtn}`}
            onClick={() => setModalShow(true)}
          >
            Free Solar Calculator
          </Button>
        </div>

        <div className={styles.featurePanel}>
          <div className={styles.feature}>
            <img src={icon1} alt="Sunlight to Energy" />
            <div>
              Convert Sunlight <br /> to Energy
            </div>
          </div>
          <div className={styles.feature}>
            <img src={icon2} alt="Lowest Price" />
            <div>
              Guaranteed Lowest <br /> Price for Solar
            </div>
          </div>
          <div className={styles.feature}>
            <img src={icon3} alt="24/7" />
            <div>
              Energy <br /> Monitoring
            </div>
          </div>
        </div>

        {modalShow && (
          <OrderModal show={modalShow} onHide={() => setModalShow(false)} />
        )}
      </Container>
    </div>
  )
}

export default MainPage
