import React, { useState } from "react"
import OrderModal from "./orderModal"
import { Button } from "reactstrap"

import styles from "../../styles/components/funnel/orderBtn.module.scss"

const OrderBtn = ({ inverted = false }) => {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <React.Fragment>
      <Button
        size="lg"
        className={inverted ? styles.invertedBtn : styles.orderBtn}
        onClick={() => setModalShow(true)}
      >
        Calculate My Savings
      </Button>

      {modalShow && (
        <OrderModal show={modalShow} onHide={() => setModalShow(false)} />
      )}
    </React.Fragment>
  )
}

export default OrderBtn
