import React from "react"
import { Link } from "gatsby"
import FadeIn from "./fadeIn"

import styles from "../../styles/components/funnel/footer.module.scss"

const Footer = () => {
  return (
    <div className={styles.body}>
      <FadeIn>
        <div className={styles.content}>
          <div className={styles.item}>
            <Link to="/products">Products</Link>
          </div>
          <div className={styles.item}>
            <Link to="/articles">Articles &amp; Publications</Link>
          </div>
          <div className={styles.item}>
            <Link to="/loans">Loans &amp; Schemes</Link>
          </div>
          <div className={styles.item}>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className={styles.item}>
            <Link to="/quote">Quotation</Link>
          </div>
        </div>
      </FadeIn>
    </div>
  )
}

export default Footer
