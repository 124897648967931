import React, { useState } from "react"
import FormInput from "./formInput"
import { FaAngleLeft } from "react-icons/fa"

import styles from "../../styles/components/funnel/order.module.scss"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

//Calculation Constants (Home/Commmercial)
//5kW
const panelCapacity5kW = 450
const maximumPanels5kW = 12
const avgDailyProd5kW = 1.5
const maxPVCapacity5kW = maximumPanels5kW * avgDailyProd5kW * 30
//20kW
const panelCapacity20kW = 450
const maximumPanels20kW = 52
const avgDailyProd20kW = 1.5
const maxPVCapacity20kW = maximumPanels20kW * avgDailyProd20kW * 30

const unitSellingRate = 22
const commercialDayRate = 21.8

//Calculation Constants (Industrial)
const panelSizeInd = 24
const panelCapacityInd = 450
const avgDailyProdInd = 1.5
const unitPriceInd = 22

// Domestic Tariffs
const lowest = 7.85
const low = 10
const medium = 27.75
const high = 32
const highest = 45

const Order = ({ closeHandler }) => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [systemType, setSystemType] = useState("onGrid")
  const [siteType, setSiteType] = useState("Home")
  const [electricityPhase, setElectricityPhase] = useState("Single")
  const [electricBill, setElectricBill] = useState(5000)
  const [transformerCapacity, setTransformerCapacity] = useState("")
  const [roofSpace, setRoofSpace] = useState("")
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [custSavings, setCustSavings] = useState("")
  const [custEarnings, setCustEarnings] = useState("")

  const handleTypeChange = e => {
    setElectricityPhase("Single")
    setElectricBill("")
    setTransformerCapacity("")
    setRoofSpace("")
    setSiteType(e.target.value)
  }

  const calculateUnits = bill => {
    let units

    if (bill <= 60 * lowest) {
      units = bill / lowest
    } else if (bill <= 60 * lowest + 30 * low) {
      units = (bill - 60 * lowest) / low + 60
    } else if (bill <= 60 * lowest + 30 * low + 30 * medium) {
      units = (bill - 60 * lowest - 30 * low) / medium + 90
    } else if (bill <= 60 * lowest + 30 * low + 30 * medium + 60 * high) {
      units = (bill - 60 * lowest - 30 * low - 30 * medium) / high + 120
    } else {
      units =
        (bill - 60 * lowest - 30 * low - 30 * medium - 60 * high) / highest +
        180
    }

    return units
    // return (bill - (60 * lowest) - (30 * low) - (30 * medium) - (60 * high) - fixedCharge + (180 * highest))/highest
  }

  const calculateEarnings = () => {
    if (siteType === "Industrial") {
      const maxRoofSpace =
        ((transformerCapacity * 1000) / panelCapacityInd) * panelSizeInd
      const minRoofSpace = Math.min(roofSpace, maxRoofSpace)
      const numPanels = Math.ceil(minRoofSpace / panelSizeInd)

      setCustSavings(numPanels * avgDailyProdInd * unitPriceInd * 30)
    } else {
      //Round customer electricity bill to nearest 100
      const billRounded = Math.round(electricBill / 100) * 100

      const units =
        siteType === "Home"
          ? Math.round(calculateUnits(electricBill))
          : Math.round(billRounded / commercialDayRate / 10) * 10
      const applicableSystemUnits =
        electricityPhase === "Single" ? maxPVCapacity5kW : maxPVCapacity20kW

      console.log(billRounded, units, applicableSystemUnits)

      setCustSavings(billRounded)
      setCustEarnings((applicableSystemUnits - units) * unitSellingRate)
    }
  }

  const handleNext = () => {
    if (siteType === "Industrial") {
      setElectricityPhase("")
    }
    calculateEarnings()
    setShowNext(true)
  }

  const handleBack = () => {
    setShowNext(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmit(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "order",
        fullName,
        email,
        phoneNumber,
        address,
        systemType,
        siteType,
        electricityPhase,
        electricBill,
        transformerCapacity,
        roofSpace,
        custSavings,
        custEarnings,
      }),
    })
      .then(() => setShowSuccess(true))
      .catch(error => {
        alert(error)
        setIsSubmit(false)
      })
  }

  return (
    <React.Fragment>
      {!showNext && !showSuccess && (
        <div className={styles.content}>
          <div>
            <h1 className={`${styles.title} text-center mb-2`}>
              Calculate Your Savings
            </h1>
            <p className="text-center mb-4 small">
              Enter details below to find out how much you can earn or save with
              solar!
            </p>
          </div>

          <div className={styles.inputDiv}>
            <label>System Type</label>
            <br />
            {/* <select
              className={styles.inputSelect}
              value={systemType}
              onChange={e => setSystemType(e.target.value)}
            >
              <option value="onGrid">On-Grid</option>
              <option value="offGrid">Off-Grid (Battery Backup)</option>
            </select> */}
            <div className="d-flex align-items-center justify-content-around">
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  name="systemType"
                  value="onGrid"
                  checked={systemType === "onGrid"}
                  onChange={e => setSystemType(e.target.value)}
                  className="me-2 mb-1"
                />{" "}
                On-Grid
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  name="systemType"
                  value="offGrid"
                  checked={systemType === "offGrid"}
                  onChange={e => setSystemType(e.target.value)}
                  id="offGrid"
                  className="me-2 mb-1"
                />
                Off-Grid (Battery Backup)
              </div>
            </div>
          </div>
          <div className={styles.inputDiv}>
            <label>Site Type</label>
            <select
              className={styles.inputSelect}
              value={siteType}
              onChange={handleTypeChange}
            >
              <option value="Home">Home</option>
              <option value="Commercial">Commercial</option>
              <option value="Industrial">Industrial</option>
            </select>
          </div>
          {siteType === "Industrial" ? (
            <div style={{ width: "100%" }}>
              <FormInput
                label="Transformer Capacity (kW)"
                type="number"
                value={transformerCapacity}
                onChange={e => setTransformerCapacity(e.target.value)}
              />
              <FormInput
                label="Estimated Roof Space (sq ft)"
                type="number"
                value={roofSpace}
                onChange={e => setRoofSpace(e.target.value)}
              />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div className={styles.inputDiv}>
                <label>Electricity Phase</label>
                <select
                  className={styles.inputSelect}
                  value={electricityPhase}
                  onChange={e => setElectricityPhase(e.target.value)}
                >
                  <option value="Single">Single Phase</option>
                  <option value="Three">Three Phase</option>
                </select>
              </div>
              <FormInput
                label="Average Electric Bill"
                type="number"
                value={electricBill}
                onChange={e => setElectricBill(e.target.value)}
              />
            </div>
          )}
          <button
            disabled={
              siteType === "Industrial"
                ? transformerCapacity == "" || roofSpace == ""
                : electricBill === ""
            }
            className={styles.nextBtn}
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      )}
      {showNext && !showSuccess && (
        <div className={styles.content2} id="potential-savings">
          <div className={styles.backBtn} onClick={handleBack}>
            <FaAngleLeft />
          </div>
          <h1 className={`${styles.title} mb-2`}>Your Potential Savings</h1>
          <p className="small mb-4">
            Find out exactly how much you can earn and save with solar!
          </p>
          <div className={styles.recommendsPage}>
            <div className={styles.recommendsDiv}>
              <div className="m-3">
                <p className={styles.recommendsTitle}>Savings</p>
                <div className="d-flex align-items-center mt-2">
                  <h1 className={styles.savings}>
                    LKR {custSavings.toLocaleString("en")}
                  </h1>
                  <p className="small m-3 mt-0 mb-0">/ Month</p>
                </div>
              </div>
              {custEarnings && (
                <div className="m-3">
                  <p className={styles.recommendsTitle}>Earnings</p>
                  <div className="d-flex align-items-center mt-2">
                    <h1 className={`${styles.savings} ${styles.savings2}`}>
                      LKR {custEarnings.toLocaleString("en")}
                    </h1>
                    <p className="small m-3 mt-0 mb-0">/ Month</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.formPage}>
            <form
              name="order"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              className="mt-4"
            >
              <input type="hidden" name="form-name" value="order" />
              <FormInput
                required
                name="fullName"
                label="Full Name"
                type="text"
                value={fullName}
                onChange={e => setFullName(e.target.value)}
              />

              <FormInput
                required
                name="email"
                label="Email Address"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <FormInput
                required
                name="phoneNumber"
                label="Phone Number"
                type="tel"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
              <FormInput
                required
                name="address"
                label="City"
                type="text"
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
              <button
                type="submit"
                className={styles.nextBtn}
                disabled={
                  !fullName || !email || !phoneNumber || !address || isSubmit
                }
              >
                Submit
              </button>
              <p className={`${styles.terms} text-center`}>
                By clicking Submit, I agree to be contacted at the number
                provided with more information. This consent is not a condition
                of purchase.
              </p>
            </form>
          </div>
        </div>
      )}
      {showNext && showSuccess && (
        <div className={styles.content} id="order-form-submitted">
          <h1 className={`${styles.title} text-center`}>Thank you!</h1>
          <p className={`${styles.successText} small`}>
            We have received your information and our team will be in touch with
            you shortly. Have a sunny day!
          </p>
          <button className={styles.nextBtn} onClick={closeHandler}>
            Done
          </button>
        </div>
      )}
    </React.Fragment>
  )
}

export default Order
