import React from "react"

const FadeIn = ({ children, delay }) => {
  return (
    <div>
      {children}
      {/* 
      <VisibilitySensor scrollDelay={0}>
        {({ isVisible }) => (
          <Spring
            from={{ opacity: 0 }}
            to={isVisible ? { opacity: 1 } : {}}
            delay={delay ? delay : 300}
          >
            {props => (
              <a.div className="box" style={props}>
                {children}
              </a.div>
            )}
          </Spring>
        )}
      </VisibilitySensor> */}
    </div>
  )
}

export default FadeIn
