import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

//Packages
import moment from "moment"
import ScrollSnap, { easeInOutCubic } from "scroll-snap"

//Components
import SEO from "../components/seo"
import MainPage from "../components/funnel/mainPage"
import FeaturePage from "../components/funnel/featurePage"
import FeaturePage2 from "../components/funnel/featurePage2"
import SpecsPage from "../components/funnel/specsPage"
import EndPage from "../components/funnel/endPage"

//Styles
import styles from "../styles/pages/funnel.module.scss"

//Videos
import installationVideoDesktop from "../videos/InstallationAnimationDesktop.mp4"
import installationVideoMobile from "../videos/InstallationAnimationMobile.mp4"
import graphVideoDesktop from "../videos/GraphAnimationDesktop.mp4"
import graphVideoMobile from "../videos/GraphAnimationMobile.mp4"

const Funnel = () => {
  const [graphVideo, setGraphVideo] = useState(graphVideoMobile)
  const [installationVideo, setInstallationVideo] = useState(
    installationVideoMobile
  )
  const [date, setDate] = useState(
    moment().add(25, "years").format(" Do MMMM YYYY, h:mm:ss a!")
  )

  const container = React.createRef()

  const bindScrollSnap = () => {
    const element = container.current
    const snapElement = new ScrollSnap(element, {
      snapDestinationY: "100vh",
      threshold: 0.1,
      easing: easeInOutCubic,
    })
    snapElement.bind()
  }

  useEffect(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (width > 768) {
      setGraphVideo(graphVideoDesktop)
      setInstallationVideo(installationVideoDesktop)
      bindScrollSnap()
    }
  }, [])

  useEffect(() => {
    const time = setInterval(() => {
      setDate(moment().add(25, "years").format(" Do MMMM YYYY, h:mm:ss a!"))
    }, 1000)

    return () => {
      clearInterval(time)
    }
  }, [date])

  const data = useStaticQuery(graphql`
    query {
      feature2: file(relativePath: { eq: "funnel/FeaturePage2.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature4: file(relativePath: { eq: "funnel/FeaturePage4.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature5: file(relativePath: { eq: "funnel/FeaturePage5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature6: file(relativePath: { eq: "funnel/off-grid-inverter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <SEO
        title="Solar For Your Home &amp; Business"
        article={false}
        pathname="/"
        description="Thinking of going solar? Adding solar panels to your roof may be the best investment you ever make! Did you know, you can earn and save money with solar?"
      />

      <form
        name="order"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="fullName" />
        <input type="email" name="email" />
        <input type="number" name="phoneNumber" />
        <input type="text" name="address" />
        <input type="text" name="systemType" />
        <input type="text" name="siteType" />
        <input type="text" name="electricityPhase" />
        <input type="number" name="electricBill" />
        <input type="number" name="transformerCapacity" />
        <input type="number" name="roofSpace" />
        <input type="number" name="custSavings" />
        <input type="number" name="custEarnings" />
      </form>
      <div ref={container} className={styles.container}>
        <MainPage />
        <FeaturePage
          video={graphVideo}
          titleSub="Savings"
          titleMain="Electricity For Less"
          content={
            <>
              <p>
                You can choose to get paid for the electricity you produce at
                the rate of LKR 37/- for 20 years. Many choose to go for this
                option as a financial investment with a payback period as low as
                3 years!
              </p>

              <p>
                Paying too much for electricity every month? Going solar might
                be the best decision you ever make! By producing your own
                electricity at home, you can save thousands each month, or
                install that air conditioner you always wanted!
              </p>
            </>
          }
        />
        <FeaturePage
          img={data.feature2.childImageSharp.fluid}
          titleSub="Design"
          titleMain="Sleek and Durable"
          content={
            <>
              <p>
                Our solar systems are sleek and durable — silently converting
                sunlight to electricity for decades to come. They boast of
                outstanding performance in weak-light conditions as well as
                being able to withstand high temperatures and local conditions.
              </p>

              <p>
                We offer a 12 Year Product Warranty and a 25 Year Linear
                Performance Warranty, which means you can rest assured that your
                solar panels will be producing electricity for you until
                {date}
              </p>
            </>
          }
        />

        <FeaturePage2
          video={installationVideo}
          titleSub="Power On"
          titleMain="Order and Installation"
          content={
            <>
              <p>
                Our comprehensive solar installation team handles everything
                from start to finish, including Site Survey, System Design,
                Finance Service, Grid Connection, Equipment Supply, Project
                Installation and finally, an impeccable After Sales Service.
              </p>
              <p>
                In compliance with all local grid standards and global safety
                standards, we always try to go above and beyond in making sure
                your solar system is always operating at peak performance.
              </p>
            </>
          }
        />
        <FeaturePage
          img={data.feature4.childImageSharp.fluid}
          titleSub="Efficiency"
          titleMain="Maximum Solar Production"
          content={
            <>
              <p>
                Fanless cooling avoids common insects like cockroaches, wasps,
                bees and geckos from causing damage.
              </p>
              <p>
                Remote fault detection and troubleshooting which means you don’t
                need to remove and fly it out to manufacturers to find out whats
                wrong.
              </p>
              <p>
                Lowest starting voltage meaning our inverters will be the first
                to wake up in the morning to generate electricity for you.
              </p>
            </>
          }
        />
        <FeaturePage
          img={data.feature6.childImageSharp.fluid}
          titleSub="Off Grid Inverter"
          titleMain="Flexible and Affordable"
          content={
            <>
              <p>
                Adopts LifePO4 chemistry battery with safety performance and
                long life time
              </p>

              <ul className="my-5 ms-4">
                <li>TUV Certified &#8226; CEC Accredited</li>
                <li>Performance Checking App</li>
                <li>Run Single Phase Completely Off-Grid</li>
                <li>IP65 Protection</li>
                <li>Compact Size &amp; Light Weight</li>
                <li>High Power Output &amp; Usable Energy Ratio</li>
                <li>Modular Design &amp; Expandable System</li>
                <li>Safest Battery &amp; Perfect Compatibility</li>
                <li>Natural Cooling &amp; Easy Installation</li>
              </ul>
            </>
          }
        />
        <FeaturePage2
          img={data.feature5.childImageSharp.fluid}
          titleSub="Earnings &amp; Production"
          titleMain="24/7 Monitoring"
          content={
            <>
              <p>
                The integrated meter in your solar system will show you exactly
                how much electricity you are producing at any moment in time -
                accessible via any smart phone, tablet or computer.
              </p>
              <p>
                Enjoy peace of mind with the ability to monitor your electricity
                production, earnings, and savings from anywhere in the world!
              </p>
            </>
          }
        />
        <SpecsPage />
        <EndPage />
      </div>
    </React.Fragment>
  )
}

export default Funnel
