import React from "react"
import ReactPlayer from "react-player"
import OrderBtn from "./orderBtn"
import FadeIn from "./fadeIn"
import FlyIn from "./flyIn"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"

import styles from "../../styles/components/funnel/featurePage2.module.scss"

const FeaturePage2 = props => {
  return (
    <div className={styles.container}>
      {/* {props.img && (
        <div
          className={styles.img}
          style={{ backgroundImage: `url(${props.img})` }}
        ></div>
      )} */}

      {props.img && (
        <div className={styles.img}>
          <Img
            fluid={props.img}
            style={{ height: "100%", width: "100%" }}
            alt=""
          />
        </div>
      )}

      {props.video && (
        <div className={styles.video}>
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) => (
              <ReactPlayer
                url={props.video}
                width="100%"
                height="100%"
                playing={isVisible ? true : false}
                controls={false}
                loop
                muted
              />
            )}
          </VisibilitySensor>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.details}>
          <div className={styles.title}>
            <h2 className={styles.titleSub}>{props.titleSub}</h2>
            <h1 className={styles.titleMain}>{props.titleMain}</h1>
          </div>
          <FlyIn>
            <div className={styles.description}>{props.content}</div>
          </FlyIn>
        </div>
        <div className="mt-5">
          <FadeIn delay={500}>
            <OrderBtn />
          </FadeIn>
        </div>
      </div>
    </div>
  )
}

export default FeaturePage2
