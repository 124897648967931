import React from "react"

const FlyIn = ({ children, delay, left }) => {
  const offset = left ? -100 : 100
  return (
    <div>
      {children}

      {/* <VisibilitySensor scrollDelay={0}>
        {({ isVisible }) => {
          return (
            <div width="100%">
              <Spring
                from={{ opacity: 0, x: offset }}
                to={isVisible ? { opacity: 1, x: 0 } : {}}
                delay={delay ? delay : 300}
              >
                {props => (
                  <a.div className="" style={props}>
                    {children}
                  </a.div>
                )}
              </Spring>
            </div>
          )
        }}
      </VisibilitySensor> */}
    </div>
  )
}

export default FlyIn
